@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url(./fonts/Roboto/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(./fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Jura-Medium";
  src: local("Jura-Medium"),
    url(./fonts/Jura/static/Jura-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Jura-Light";
  src: local("Jura-Light"),
    url(./fonts/Jura/static/Jura-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Jura-Medium";
  src: local("Jura-Medium"),
    url("./fonts/Jura/static/Jura-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Fairfax";
  src: local("Fairfax"),
    url("./fonts/Fairfax/Fairfax.ttf") format("truetype");
  font-weight: normal;
}

html,
body {
  margin: 0;
  scroll-behavior: smooth;
}
